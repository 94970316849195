import { Component } from "@angular/core";

import { NavController } from "@ionic/angular";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Network } from "@ionic-native/network/ngx";
import { RecipeDetailPage } from "./recipe-detail/recipe-detail.page";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  private isPlatformReady = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private network: Network,
    protected navController: NavController,
    protected deeplinks: Deeplinks,
    private uniqueDeviceID: UniqueDeviceID
  ) {
    console.log("calling initializeApp");

    if (localStorage.getItem("welcomeRead") == "true")
      this.navController.navigateRoot(["/menu/explore"]);
    else
      this.navController.navigateRoot(["/"]);

    this.initializeApp();
  }

  async initializeApp() {
    try {
      await this.platform.ready();
    } catch (error) {
      console.log("platform ready error: ", error);
    }

    let uuid = "123456";
    try {
      uuid = await this.uniqueDeviceID.get();
    } catch (error) {
      console.log("got UUID error: ", error);
    }

    console.log(uuid);
    localStorage.setItem("deviceId", uuid);

    this.isPlatformReady  = true;
    const path            = { "/home/:productId": RecipeDetailPage };

    this.deeplinks
      .routeWithNavController(this.navController, path)
      .subscribe(
        match => console.log("Successfully matched route", match),
        nomatch => console.error("Got a deeplink that didn't match", nomatch)
      );

    this.statusBar.styleDefault();
    this.splashScreen.hide();
  }
}
