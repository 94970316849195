import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialSharingService {
    constructor(
    private socialSharing: SocialSharing,
  ) { }

  share(receipeId: String) {
    const url = environment.apiEndpointShare + '/home/' + receipeId;
    return this.socialSharing.share(null, null, null, url);
  }

}
