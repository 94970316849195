import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedTabService {

  private showTabs = new BehaviorSubject(false);
  currentStateOfTabs = this.showTabs.asObservable();

  constructor() { }

  changeStateOfTabs(tab: boolean) {
    this.showTabs.next(tab)
  }
}

