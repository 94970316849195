import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HelperService } from "../_helpers/HelperService";
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { ɵa } from 'td-aio-auth';
import { environment } from './../../environments/environment';

@Injectable()
export class NetworkDetcterInterceptor implements HttpInterceptor {

  constructor(private uniqueDeviceID: UniqueDeviceID, private router: Router, public helperService: HelperService, public authenticationService: ɵa) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (navigator.onLine == false) {
      throw ('INTERNET CONNECTION NOT FOUND');
    }

    if (request.url.indexOf(environment.apiEndpoint) >= 0) {
      let uuid = localStorage.getItem('deviceId');
      // console.log('uuid==========>>>>>>> = '+uuid);
      uuid = uuid ? uuid : '123456';
      // console.log('uuid........... = '+uuid);
      request = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'deviceid': uuid

        })
      });

      if (this.authenticationService.currentUserValue != null) {
        if (this.authenticationService.currentUserValue.token != "") {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.authenticationService.currentUserValue.token}`
            }
          });
        }

      }

    }

    return next.handle(request);
  }

}