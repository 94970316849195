import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams, PickerController, AlertController, ActionSheetController, LoadingController } from '@ionic/angular';
import { IngredientService } from './../services/ingredient.service';
import { HelperService } from "../_helpers/HelperService";
import { Router } from '@angular/router';
import { SharedTagIngredientService } from './../services/shared-tag-ingredient.service';
import { PickerOptions } from '@ionic/core';
import { RegistrationService } from './../services/registration.service';
import { SharedTabService } from "../services/shared-tab.service";
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ImageGalleryPage } from '../image-gallery/image-gallery.page';
import { SharedService } from '../services/shared.service';
import { SharedPickerService } from "../services/shared-picker.service";

@Component({
  selector: 'app-ingredients-identified',
  templateUrl: './ingredients-identified.page.html',
  styleUrls: ['./ingredients-identified.page.scss'],
})
export class IngredientsIdentifiedPage implements OnInit {
  public identifiedIngredients: any;
  public pantry_img_id: any;
  public backButtonFlag = true;
  public QtyCounter: any = [];
  public pickerQtyCounter: any = [];
  public ingQty: any;
  public ingUnit: any;
  selected = ['', '', ''];
  public addItemButtonIsActive  = false;
  public updateQtyBtnIsActive   = true;

  constructor(
    public loadingController: LoadingController,
    public pickerCtrl: PickerController,
    private sharedTagIngredientService: SharedTagIngredientService,
    private sharedTabService: SharedTabService,
    public router: Router,
    public helperService: HelperService,
    public ingredientService: IngredientService,
    public registrationService: RegistrationService,
    public navParams: NavParams,
    public navController: NavController,
    public actionSheetController: ActionSheetController,
    public sharedService : SharedService,
    public sharedPickerService:SharedPickerService,
    public modalController: ModalController,
    private alertController: AlertController
  ) {
    this.identifiedIngredients = navParams.get('fetchedIngredients');

    this.identifiedIngredients = this.identifiedIngredients.map(ingredient => {
      const coords = ingredient.coordinate ? ingredient.coordinate[0] : {}

      const label = !coords.x && !coords.y ? '\n (extra)' : ''

      ingredient['coordinatesLabel'] = label

      return ingredient
    })

    this.pantry_img_id = navParams.get('pantry_img_id');

    if (this.identifiedIngredients.length > 0)
      this.addItemButtonIsActive = true;

    console.log(this.identifiedIngredients);
  }

  ngOnInit() {
  }

  backToExplore() {
    this.modalController.dismiss('gotoBarcode');
    // this.navController.navigateRoot(['menu/barcode']);
  }

  back() {
    console.log('back call');
    this.modalController.dismiss('gotoimagetag');
  }

  addItemToPantry() {
    this.addItemButtonIsActive = false;

    let pantryObj: any      = {};
    pantryObj.pantry_img_id = this.pantry_img_id;
    pantryObj.image_type    = 'pantry';

    let deleteObj: any      = {};
    deleteObj.image_ids     = [this.pantry_img_id];
    deleteObj.image_type    = 'pantry';

    this.ingredientService.autoAddIngredient(pantryObj)
    .subscribe(
      resp => {
        console.log(resp);
        this.addItemButtonIsActive = true;
        this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');

        this.sharedService.shiftImgData();
        this.deletePantryImages(deleteObj);
        this.modalController.dismiss('ingredient');
      },
      error => {
        this.addItemButtonIsActive = true;
        console.log(error);
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        this.modalController.dismiss();
      }
    );

  }

  deletePantryImages(pantryImgObj){
    this.ingredientService.deletePantryImage(pantryImgObj)
    .subscribe(
      res => { },
      err => { }
    );
  }

  deleteIdentifiedIng(ingredient, index) {
    // console.log(ingredient);
    this.backButtonFlag = false;
    this.identifiedIngredients.splice(index, 1);

    console.log(this.identifiedIngredients);

    let deleteIdentifiedObj: any = {};
    deleteIdentifiedObj.pantry_img_id = this.pantry_img_id;
    deleteIdentifiedObj.label_obj_id = ingredient.label_obj_id;
    deleteIdentifiedObj.type = 'pantry';
    this.ingredientService.deleteIdentifiedIngredient(deleteIdentifiedObj).subscribe(resp => {
      console.log(resp);
      this.updateWhatz2eatGallery();
      // this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');

    },
      error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      })
  }

  updateWhatz2eatGallery() {
    let galleryImages: any = [];

    this.ingredientService.getPantryImages('pantry_images').subscribe(
      (res: any) => {
        galleryImages = res.data;
        // console.log(galleryImages);

        if (galleryImages.length > 0) {
          for (let i = 0; i < galleryImages.length; i++) {
            if (galleryImages[i].pantry_img_id == this.pantry_img_id) {
              // console.log(galleryImages[i]);
              let arr:any = [];
              arr.push(galleryImages[i]);
              console.log(arr);
              this.sharedService.setImgData(arr);
              this.sharedTagIngredientService.changeTagIngredients(galleryImages[i]);
              this.backButtonFlag = true;
            }
          }
        }

      },
      error => {
        this.helperService.dismissPresentLoading();
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }

  /**
   * @method : To format the data as per Quantity counter
   */
  formatCounter(quantity) {
    let optObj: any = {}
    return optObj = {
      "text": quantity,
      "value": quantity
    }
  }
  /**
   * Method to Update quantity of identified ingredient
   */
  updateQty(idx) {
    this.updateQtyBtnIsActive = false;
    console.log(idx);
    // if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.ingredientService.getQtyCounter()
      .subscribe(res => {
        let qtyUnits:any = [];
        for (let i = 0; i < res.data.units.length; i++) {
          qtyUnits.push(res.data.units[i].unit_key)
        }

        this.QtyCounter = [
          {
            name: 'abs_qty',
            selectedIndex: 0,
            options: res.data.abs_qty.map(this.formatCounter)
          },
          {
            name: 'fraction_qty',
            selectedIndex: 0,
            options: res.data.fraction_qty.map(this.formatCounter)
          },
          {
            name: 'units',
            selectedIndex: 0,
            options: qtyUnits.map(this.formatCounter)
          }
        ];
        this.pickerQtyCounter = this.QtyCounter;
        console.log(this.pickerQtyCounter);
        if (this.pickerQtyCounter.length > 0) {
          console.log('in onup');
          this.openPicker(idx);
        } else {
          this.updateQtyBtnIsActive = true;
        }
      },
      error => {
        this.updateQtyBtnIsActive = true;
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        this.modalController.dismiss();
      })
    // } else {
    //   this.modalController.dismiss();
    //   this.helperService.alertLogin(this.router.url);
    // }
  }

  async openPicker(i) {

    let opts: PickerOptions = {
      cssClass: 'quantity-picker',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'custom-line-btn lightColor',
          role: 'cancel',
          handler: () => {
            this.updateQtyBtnIsActive = true;
            this.pickerQtyCounter = [];
          }
        },
        {
          text: 'Save',
          cssClass: 'custom-line-btn darkColor',
          handler: () => {
            this.updateQtyBtnIsActive = true;
            /*
            | Capture the data on wheel selection on Done or cancel
            */
            picker.onDidDismiss().then(async data => {
              let abs_qty       = await picker.getColumn('abs_qty');
              let fraction_qty  = await picker.getColumn('fraction_qty');
              let units         = await picker.getColumn('units');
              this.selected = [
                abs_qty.options[abs_qty.selectedIndex].value,
                fraction_qty.options[fraction_qty.selectedIndex].value,
                units.options[units.selectedIndex].value
              ];

              let ingredientObj: any = {};
              ingredientObj.content_id    = this.identifiedIngredients[i].ingredient_id;
              ingredientObj.content_qty   = this.selected[0] + this.selected[1];
              ingredientObj.unit          = this.selected[2];
              ingredientObj.type_img_id   = this.pantry_img_id;
              ingredientObj.type          = 'pantry';

              console.log(ingredientObj);
              this.ingQty   = this.selected[0] + this.selected[1];
              this.ingUnit  = this.selected[2];
              this.identifiedIngredients[i].qty   = '';
              this.identifiedIngredients[i].unit  = '';
              this.identifiedIngredients[i].qty   = this.selected[0] + this.selected[1];
              this.identifiedIngredients[i].unit  = this.selected[2];


              this.ingredientService.imgEditIngredient(ingredientObj).subscribe(
              (res: any) => {
                this.pickerQtyCounter = [];
                this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
              },
              error => this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast'));
              console.log(this.selected);
            });
          }
        }
      ],
      columns: this.pickerQtyCounter
    };

    let picker = await this.pickerCtrl.create(opts);
    picker.present();
  }

}
