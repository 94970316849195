import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'td-aio-auth';

const routes: Routes = [
  { path: '', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  //{ path: '', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  //{ path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordPageModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  // { path: 'generic-setting-filter', loadChildren: './generic-setting-filter/generic-setting-filter.module#GenericSettingFilterPageModule' },

  // {
  //   path: 'tabs',
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './tabs/tabs.module#TabsPageModule'
  //     }
  //   ]
  // },
  {
    path: 'menu',
    //canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './menu/menu.module#MenuPageModule'
      }
    ]
  },

  /* { path: 'add-dislike-ingredients', loadChildren: './add-dislike-ingredients/add-dislike-ingredients.module#AddDislikeIngredientsPageModule' },

  { path: 'shoping-list', loadChildren: './shoping-list/shoping-list.module#ShopingListPageModule' },
  */
  // { path: 'categories', loadChildren: './categories/categories.module#CategoriesPageModule' },

  //{ path: 'edit-ingredient', loadChildren: './edit-ingredient/edit-ingredient.module#EditIngredientPageModule' },

  { path: 'recipe-list', loadChildren: './recipe-list/recipe-list.module#RecipeListPageModule' },
  { path: 'collection-recipes', loadChildren: './collection-recipes/collection-recipes.module#CollectionRecipesPageModule' },

  //{ path: '', loadChildren: './speak/speak.module#SpeakPageModule' },
  // { path: 'recipe-detail', loadChildren: './recipe-detail/recipe-detail.module#RecipeDetailPageModule' },
  // { path: 'recipe-nutrition', loadChildren: './recipe-nutrition/recipe-nutrition.module#RecipeNutritionPageModule' },
  //{ path: 'add-ingredients', loadChildren: './add-ingredients/add-ingredients.module#AddIngredientsPageModule' },
  // { path: 'image-tag', loadChildren: './image-tag/image-tag.module#ImageTagPageModule' },
  { path: 'ingredients-identified', loadChildren: './ingredients-identified/ingredients-identified.module#IngredientsIdentifiedPageModule' },
  { path: 'recipe-share', loadChildren: './recipe-share/recipe-share.module#RecipeSharePageModule' },
  { path: 'receipt-ingredient-identified', loadChildren: './receipt-ingredient-identified/receipt-ingredient-identified.module#ReceiptIngredientIdentifiedPageModule' },
  // { path: 'search-recipes', loadChildren: './search-recipes/search-recipes.module#SearchRecipesPageModule' },
  { path: 'search-filter-list', loadChildren: './search-filter-list/search-filter-list.module#SearchFilterListPageModule' },
  { path: 'custom-camera', loadChildren: './custom-camera/custom-camera.module#CustomCameraPageModule' },  { path: 'whatznew', loadChildren: './whatznew/whatznew.module#WhatznewPageModule' },
  { path: 'say', loadChildren: './say/say.module#SayPageModule' },
  { path: 'voice-ingredients-identified', loadChildren: './voice-ingredients-identified/voice-ingredients-identified.module#VoiceIngredientsIdentifiedPageModule' },
  { path: 'deny-gallery-permission', loadChildren: './deny-gallery-permission/deny-gallery-permission.module#DenyGalleryPermissionPageModule' },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
