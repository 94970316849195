import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root'
})
export class CheckNetworkConnectionService {

  constructor(private network: Network) { }

  /**
   * Methdod to chec network connection
   */
  async isNetworkConnected(){
          // watch network for a disconnection
     /*  let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
        console.log("offline")
        return "offline";
      });

      // stop disconnect watch
      disconnectSubscription.unsubscribe(); */


      // watch network for a connection
      await this.network.onConnect().subscribe(() => {
        
        return 'online';
      });

      // stop connect watch
      //connectSubscription.unsubscribe();
  }
}
