import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class SharedTagIngredientService {
  private tagIngredientSource = new BehaviorSubject({});

  constructor() { }

  changeTagIngredients(data: object) {
    this.tagIngredientSource.next(data)
  }

  clearData() {
    this.tagIngredientSource.next({});
  }

  getData(): Observable<any> {
    return this.tagIngredientSource.asObservable();
  }



}
// this.data.currentMessage.subscribe(message => this.message = message)