import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

  constructor(public handler: HttpBackend, private http: HttpClient) { }
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  getCategories(): Observable<any> {

    return this.http
      .get(environment.apiEndpoint + '/list_ingredients', this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  getPantryList(data): Observable<any> {

    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'), params: { 'stock': data }
    };

    return this.http
      .get(environment.apiEndpoint + '/list_pantry', option)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  getPantryImages(imgtype): Observable<any> {
    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'), params: { 'image_type': imgtype }
    };
    return this.http
      .get(environment.apiEndpoint + '/list_images', option)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  editIngredient(data: Object): Observable<any> {
    data = JSON.stringify(data);
    console.log(data);

    const url = environment.apiEndpoint + '/edit_ingredient'; //old api

    return this.http
      .post(url, data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  imgEditIngredient(data: Object): Observable<any> {
    data = JSON.stringify(data);
    console.log(data);

    const url = environment.apiEndpoint + '/Img_edit_ingredient_NIN_pantry';
    return this.http
      .post(url, data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  presignedurlForIngredient(data: any): Observable<any> {

    let presignedHeaderOption: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'image_type': data.image_type, 'extension': data.extension }
    };
    return this.http
      .get(environment.apiEndpoint + '/get_presigned_url?rand='+Math.random(), presignedHeaderOption)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  imgUpload(blob, presignedUrl, ext): Observable<any> {
    let contentType: any;
    let newhttp: any;
    newhttp = new HttpClient(this.handler);

    // alert('in imgupload service');
    // if (ext == '.jpg') {
    //   contentType = 'image/jpeg';
    // } else {
    //   contentType = 'image/png';
    // }
    // this.http = new HttpClient(this.handler);
    // let opt: any = { headers: new HttpHeaders().set('Content-Type', contentType) };
    let opt: any = { headers: new HttpHeaders().set('Content-Type', 'image/jpeg') };

    // return this.http
    return newhttp
      .put(presignedUrl, blob, opt)
      .pipe(map(res => {
        console.log(res);
        // alert('in imgupload success');
        if (res == null) {
          return true;
        }

      }, err => {
        // alert('in imgupload error');
        throwError(err);
      })
      );
  }
  updateS3Meta(presignedUrl, recipeId?): Observable<any> {

    let data: any = {};
    data.presigned_url = presignedUrl;
    if(recipeId){
      data.recipe_id = recipeId
    }
    
    data = JSON.stringify(data);
    // console.log(data);
    // alert(data);
    return this.http
      .post(environment.apiEndpoint + '/update_s3_upload_meta', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  checkImgStatus(data): Observable<any> {

    data = JSON.stringify(data);

    return this.http
      .post(environment.apiEndpoint + '/check_async_status', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  /**
   * Method to get Quantity picker data
   * @method : Get
   * @return : Object 
   */
  getQtyCounter(): Observable<any> {
    return this.http.get(environment.apiEndpoint + "/qty_counter")
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }

  /**
   * @method: Method to get ingredients list
   * @purpose: To list all ingredients where user can Add for their own 
   */
  list_ingredients(): Observable<any> {
    return this.http.get(environment.apiEndpoint + "/list_ingredients")
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }
  /**
   * show_dislike_ingredients
   */
  show_dislike_ingredients(): Observable<any> {
    return this.http.get(environment.apiEndpoint + "/show_dislike_ingredients")
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }

  /**
   * @method to add Ingredients
   */

  add_ingredients(data): Observable<any> {
    // data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/add_ingredient', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  /**
  * @method to add Ingredients
  */

  add_to_wish_list(data: Object): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/add_to_wish_list', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }


  /**
   * @method to delete pantry ingredient
   * @param categoryId & contentId
   * @ouput object
   */

  deleteIngredient(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/delete_ingredient', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  uniqueIngredients(pantryId: string): Observable<any> {
    const url = `${environment.apiEndpoint}/Img_get_ingredient_NIN_pantry?type_img_id=${pantryId}&type=pantry`

    return this.http
      .get(url, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  autoAddIngredient(data): Observable<any> {
    data = JSON.stringify(data);
    console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/auto_add_ingredients', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  /**
   * Method to add ingredients in pantry using voice
   */
  voiceAddIngredient(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/voice_pantry_ingredient_add_api', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  deleteIdentifiedIngredient(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/pantry_ingredient_delete_api', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  /**
  * @method to delete pantry ingredient
  * @param categoryId & contentId
  * @ouput object
  */

  delete_from_wish_list(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/delete_from_wish_list', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  /**
   * clear_all
   */
  clear_all(type): Observable<any> {

    return this.http
      .get(environment.apiEndpoint + '/clear_all?delete_flag=' + type, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }
  add_ingredient_pantry_img(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/add_ingredient_pantry_img', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }
  update_ingredient_pantry_img(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/update_ingredient_pantry_img', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }
  deletePantryImage(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/delete_pantry_img', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }


}
