import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedPickerService {
  private quantityPickerSource = new BehaviorSubject({});

  constructor() { }

  changequantityPicker(data: any) {
    this.quantityPickerSource.next(data)
  }
  getQuantityPicker(): Observable<any> {
    return this.quantityPickerSource.asObservable();

  }
}

