import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  presignedurl(data: any): Observable<any> {

    let presignedHeaderOption: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'image_type': data.image_type, 'extension': data.extension }
    };
    return this.http
      .get(environment.apiEndpoint + '/get_presigned_url_new_user', presignedHeaderOption)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  imgUpload(blob, presignedUrl, ext): Observable<any> {
    let contentType: any;

    // if (ext == '.jpg') {
    //   contentType = 'image/jpeg';
    // } else {
    //   contentType = 'image/png';
    // }
    // this.http = new HttpClient(this.handler);
    // let opt: any = { headers: new HttpHeaders().set('Content-Type', contentType) };
    let opt: any = { headers: new HttpHeaders().set('Content-Type', 'image/jpeg') };

    // return this.http
    return this.http
      .put(presignedUrl, blob, opt)
      .pipe(map(res => {
        console.log(res);
        if (res == null) {
          return true;
        }

      }, err => {
        console.log(err);
        throwError(err);
      })
      );


  }
  updateS3(presignedUrl): Observable<any> {

    let data: any = {};
    data.presigned_url = presignedUrl;
    data = JSON.stringify(data);

    return this.http
      .post(environment.apiEndpoint + '/update_s3_upload_meta_new_user', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  checkStatus(data): Observable<any> {

    data = JSON.stringify(data);

    return this.http
      .post(environment.apiEndpoint + '/check_async_status', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  signup(data: Object): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/create_user', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }
  generateOtp(data: Object): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/reset_pass_otp', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })

      );

  }
  emailVerificationOtp(data: Object): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/email_verify_otp', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })

      );

  }
  VerifyOtp(data: Object): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/verify_otp', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })

      );

  }
  resetPassword(data: Object): Observable<any> {
    data = JSON.stringify(data);

    return this.http
      .post(environment.apiEndpoint + '/reset_password', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  /**
   * Instagram logout service
   */
  logOutInsta() {
    return this.http
      .post("http://instagram.com/accounts/logout", '', {})
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  searchAppleSignin(state) {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Authorization': 'Bearer ' + this.tokenSSO,
    //     'Content-Type': 'application/json'
    //   })
    // };
    // return Observable.create(observer => {
    //   // At this point make a request to your backend to make a real check!
    //   console.log("on appelle BACKEND encoded url " + this.getAppleSignInUrl);
    //   this.http.get(this.getAppleSignInUrl + "?state=" + state, options)
    //     .pipe(retry(1))
    //     .subscribe(res => {
    //       observer.next(res);
    //       observer.complete();
    //     }, error => {
    //       observer.next();
    //       observer.complete();
    //       console.log(error);// Error getting the data
    //     });
    // });
  }

}
