import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { RecipeService } from '../services/recipe.service';
import { HelperService } from '../_helpers/HelperService';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LoadingController, ActionSheetController, NavController } from '@ionic/angular';
import { IngredientService } from './../services/ingredient.service';
import { ReceiptIngredientIdentifiedPage } from './../receipt-ingredient-identified/receipt-ingredient-identified.page';
import { SharedTagIngredientService } from '../services/shared-tag-ingredient.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { ɵa } from 'td-aio-auth';

@Component({
  selector: 'app-recipe-share',
  templateUrl: './recipe-share.page.html',
  styleUrls: ['./recipe-share.page.scss'],
  providers: [Camera]
})
export class RecipeSharePage implements OnInit {

  public taskId: any;
  public currentIndex = 0;
  public activeRecipeTab = 'rate';
  public receipeId: any;
  public showRateFlag = true;
  public tappedUserRating: any;
  public userRatings = [];
  public cookToggle = 0;
  public stepsToggle = 0;
  public ingQuantityToggle = 0;
  public comment = '';
  public feedBack: any;
  public loading: any;
  public recipeImage: any = '';
  public listType = 'pantry_images';
  public presignedurl: any;
  public imageData: any = {};
  public clickedImage = "../../assets/images/camera.png";
  public clickedImg = true;
  public isImageCaptured: boolean = false;
  public recipeeImageId: any;
  public base64ImageG: string;
  public cameraRollStatus: any = true;
  public encoded_base64_string: any;
  public showbottomtoast: boolean = true;

  private isRatingSaved = false;

  constructor(public helperService: HelperService,
    public api: RecipeService,
    navParams: NavParams,
    private modalController: ModalController,
    private socialSharing: SocialSharing,
    public camera: Camera,
    public _ingredientService: IngredientService,
    public loadingController: LoadingController,
    private sharedTagIngredientService: SharedTagIngredientService,
    private router: Router,
    public platform: Platform,
    private base64ToGallery: Base64ToGallery,
    public authenticationService: ɵa
  ) {
    this.receipeId = navParams.get('receiptId');
    this.tappedUserRating = navParams.get('tappedRating');
    this.recipeImage = navParams.get('recipe_image');
  }

  ngOnInit() {


  }

  ionViewDidEnter() {
    this.fetchFeedback();
    for (let i = 1; i <= 5; i++) {
      if (i <= this.tappedUserRating) {
        this.userRatings.push({ 'rated': true });

      } else {
        this.userRatings.push({ 'rated': false });
      }
    }
  }
  fetchFeedback() {
    console.log('fetch feed')
    if (this.receipeId != "undefined") {
      this.api.fetchFeedbackOfRecipe(this.receipeId)
        .subscribe((res: any) => {
          console.log(res);
          this.feedBack = res.data;
          this.cookToggle = this.feedBack.cooktime;
          this.stepsToggle = this.feedBack.steps;
          this.ingQuantityToggle = this.feedBack.ing_quantity;
          this.comment = this.feedBack.comment;

        },
          error => {
            console.log('error rate');
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          })
    }
  }

  openTab(tabName, idx) {
    this.currentIndex = idx;
    this.activeRecipeTab = tabName;
  }


  public dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    // else
    //   byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }



  /* This is real captureImageByCamera function */

  public saveImageToGallery() {

    return this.base64ToGallery.base64ToGallery(btoa(this.encoded_base64_string), { prefix: '_img', mediaScanner: true }).then(
      res => console.log('Saved image to gallery ', res),
      err => console.log('Error saving image to gallery ', err)
    );

  }

  captureImageByCamera() {

    const options: CameraOptions = {
      quality: 100,
      sourceType: this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):

      this.encoded_base64_string = atob(imageData);
      let base64Image = 'data:image/jpeg;base64,' + imageData;
      this.base64ImageG = base64Image;
      this.isImageCaptured = true;
      this.clickedImage = base64Image;


      let imgObj: any = {}
      imgObj.img = this.base64ImageG;;
      let blob = this.dataURItoBlob(this.base64ImageG);
      let presignedObj: any = {};
      presignedObj.image_type = 'user_recipe_image';
      presignedObj.extension = '.jpg';
      this.getPresignedUrl(blob, presignedObj);


    }, (err) => {
      // Handle error
      console.error(JSON.stringify(err));

    });


    this.showbottomtoast = true;

  }

  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      message: msg,
      id: 'img_gallery',
      spinner: null,
      cssClass: 'funky-animation-wrapper',
    });
    await this.loading.present();
  }

  getPresignedUrl(blob, data) {
    let msg: any = 'Uploading Image...';
    this.presentLoading(msg);
    this._ingredientService.presignedurlForIngredient(data).subscribe(
      (res: any) => {
        if (res) {
          // console.log(res);
          // alert('getPresignedUrl');
          let successData = res;
          if (successData.status == 200) {
            this.presignedurl = successData.presigned_url;
            // let presignedimg = this.presignedurl.split(".jpg");
            // this.clickedImage = presignedimg[0]+".jpg";
            // console.log('.......................................................................');
            // console.log(this.clickedImage);

            // alert(this.clickedImage);
            this.clickedImg = false;

            this.uploadUserImg(blob, data.extension);

          }
        }
      }, error => {
        // alert(JSON.stringify(error));
        this.dismissLoading();
      }
    )

  }
  uploadUserImg(blob, ext) {
    // alert('in uploadUserImg');
    this._ingredientService.imgUpload(blob, this.presignedurl, ext).subscribe(
      (res: any) => {
        if (res) {
          let successData = res;
          // alert('in success');
          this.updateS3UploadImg();
        }
      }, error => {
        // alert('in uploadUserImg error');
        this.dismissLoading();

      }
    )
  }

  displayImageTag(data) {
    // let navigationExtras: NavigationExtras = {
    //   state: {
    //     imgData: data
    //   }
    // };
    this.sharedTagIngredientService.changeTagIngredients(data);
    // this.navController.navigateForward(['/menu/pantry/edit-ingredient'], navigationExtras);
    this.router.navigate(['menu/barcode/image-tag']);
  }


  fetchPantryImages() {
    this._ingredientService.getPantryImages(this.listType).subscribe(
      (res: any) => {
        console.log(res);
        if (res.data.length > 0) {
          let totalImg: number = 0;
          totalImg = res.data.length;
          this.imageData = res.data[totalImg - 1];
          //  this.clickedImage = this.imageData.img_url_large;
          // this.imageData = res.data[0];
          if (this.listType == 'receipt_images') {
            this.receiptIngredientIdentifiedModal(this.imageData);
          } else {
            setTimeout(() => {
              this.displayImageTag(this.imageData);
            }, 100)
          }


        }
      },
      error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }


  async receiptIngredientIdentifiedModal(data) {
    const modal = await this.modalController.create({
      component: ReceiptIngredientIdentifiedPage,
      componentProps: {
        'receiptIngredients': data
      }
    });
    modal.onDidDismiss().then((dataReturned) => {
      console.log(dataReturned);
      if (dataReturned.data == 'ingredient') {
        this.router.navigate(['/menu/barcode/onboarding-step1']);
        //this.navController.navigateRoot(['/menu/barcode/onboarding-step1']);
      }
    });

    return await modal.present();
    //     const { data } = await modal.onWillDismiss();
    // console.log(data);
  }



  checkStatus() {

    let statusObj: any = {};
    statusObj.task_id = this.taskId;
    this._ingredientService.checkImgStatus(statusObj).subscribe(
      (res: any) => {
        if (res) {

          // alert(JSON.stringify(res));
          let successData: any;
          successData = res.data;
          // console.log(successData);
          if (successData.status == 'complete') {
            this.dismissLoading();
            this.recipeeImageId = successData.user_recipe_image_id;



            // this.saveImageToGallery();
            // console.log('................................................');
            //  console.log(successData.user_recipe_image_id);
            //  console.log(JSON.stringify(successData));

            // alert(successData.user_recipe_image_id);
            // alert(JSON.stringify(successData));


            // this.fetchPantryImages();
            this.dismissLoading();


          } else if (successData.status == 'to_do') {
            setTimeout(() => {
              this.checkStatus();
            }, 2000);
          } else if (successData.status == 'on_progreess') {
            setTimeout(() => {
              this.checkStatus();
            }, 2000);
          } else if (successData.status == 'in_progress') {
            setTimeout(() => {
              this.checkStatus();
            }, 2000);
          } else {
            this.dismissLoading();
            console.log('failed');
          }
        }
      }, error => {
        this.dismissLoading();
        console.log(JSON.stringify(error));
        // alert('error checkStatus');

      }
    )
  }


  updateS3UploadImg() {
    this._ingredientService.updateS3Meta(this.presignedurl, this.receipeId).subscribe(
      (res: any) => {
        if (res) {


          // alert('in updateS3UploadImg');
          this.taskId = res.task_id;
          this.checkStatus();
        }
      }, error => {
        this.dismissLoading();
        // alert('in updateS3UploadImg error');
        // alert(JSON.stringify(error));
        console.log(error);
      }
    )
  }

  public modifyUrl(url) {
    if (this.isImageCaptured) {
      return url + "?userrecipeeimageid=" + this.recipeeImageId
    } else {
      return url;
    }
  }
  openShare() {
    let url = environment.apiEndpointShare + '/home/' + this.receipeId;
    let furl = this.modifyUrl(url);
    this.socialSharing.share(null, null, null, furl);
  }


  openTwitterShare() {
    let url = environment.apiEndpointShare + '/home/' + this.receipeId;
    let furl = this.modifyUrl(url);
    this.socialSharing.shareVia("com.apple.social.twitter", null, null, null, furl);
  }

  openFacebookShare() {
    let url = environment.apiEndpointShare + '/home/' + this.receipeId;
    let furl = this.modifyUrl(url);
    this.socialSharing.shareViaFacebook(null, null, furl);
  }

  openInstagramShare() {
    let url = environment.apiEndpointShare + '/home/' + this.receipeId;
    let furl = this.modifyUrl(url);

    if (this.isImageCaptured) {

      this.socialSharing.shareViaInstagram(furl, this.clickedImage);
    } else {
      this.socialSharing.shareViaInstagram(furl, this.recipeImage);
    }


  }

  /*added instagram */


  /**
   * toggle between tabs
   */
  onRateShare(type) {
    if (type == 'rate') {
      this.showRateFlag = true;
    } else {
      this.showRateFlag = false;
    }
  }
  /**
   * go to share tab
   */
  next() {
    this.showRateFlag = false;
  }
  /**
   * back to rate tab
   */
  back() {
    this.showRateFlag = true;
  }
  /**
   * done feedback and back to explore
   */
  done() {
    if (!this.isRatingSaved) {
      this.isRatingSaved = true;
      this.save();
    }

    if (this.isImageCaptured) {
      this.showbottomtoast = false;

      if (this.cameraRollStatus) {
        this.presentLoading("saving image");
        // this.presentLoading("Saving.....");
        setTimeout(() => {
          this.saveImageToGallery().then(
            (result) => {
              this.dismissLoading();
              this.modalController.dismiss('done');
              // this.presentLoading("Image saved");
              // setTimeout(()=> {

              // },2000);
              // alert("model dismissed");
              // .then((result) => {
              //   this.modalController.dismiss('done');
              // });
            });
        }, 1000)
        // this.modalController.dismiss('done');
        // this.dismissLoading();
      } else {
        this.dismissLoading();
        this.modalController.dismiss('done');
      }

    } else {
      this.modalController.dismiss('done');
    }
    // if (this.cameraRollStatus) {
    //   // this.presentLoading("Saving.....");
    //   // this.saveImageToGallery().then((result)=>{
    //   //   this.modalController.dismiss('done');
    //   //   this.dismissLoading();
    //   // })
    // } else {
    //   this.modalController.dismiss('done');
    // }

    // this.showbottomtoast = false;

    // let imgObj: any = {}
    // imgObj.img = this.base64ImageG;;
    // let blob = this.dataURItoBlob(this.base64ImageG);
    // let presignedObj: any = {};
    // presignedObj.image_type = 'user_recipe_image';
    // presignedObj.extension = '.jpg';
    // this.getPresignedUrl(blob, presignedObj);

    // if(this.cameraRollStatus){
    //   this.presentLoading("Saving.....");
    //   this.saveImageToGallery().then((result)=>{
    //     this.modalController.dismiss('done');
    //     this.dismissLoading();
    //   })
    // }else{
    //   this.modalController.dismiss('done');
    // }
    // this.modalController.dismiss('close');
    // this.dismissLoading();
  }

  /**
   * Method to rate and share recipe 
   */
  save() {

    let saveObj: any = {};
    saveObj.cookTime = this.cookToggle;
    saveObj.steps = this.stepsToggle;
    saveObj.ingQuantity = this.ingQuantityToggle;
    saveObj.comment = this.comment;
    saveObj.recipe_id = this.receipeId;
    console.log(saveObj);

    if (this.receipeId != "undefined") {
      this.api.saveShareRating(saveObj).subscribe(
        res => {
          console.log(res);
          if (!this.isRatingSaved) {
            this.isRatingSaved = true;
            
          }
          this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
        },
        error => {
          console.log('error rate');
          this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          this.modalController.dismiss('expiredsession');
        });
    }


  }


  giveRating(rating) {
 
      this.tappedUserRating = (rating + 1);
      let postObj = {
        "recipe_id": this.receipeId,
        "rating": this.tappedUserRating
      }
      if (this.receipeId != "undefined") {
        this.api.giveRecipeRating(postObj)
          .subscribe(res => {
            console.log('success rate');
            this.userRatings = [];
            for (let i = 1; i <= 5; i++) {
              if (i <= this.tappedUserRating) {
                this.userRatings.push({ 'rated': true });

              } else {
                this.userRatings.push({ 'rated': false });
              }
            }

            // this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
          },
            error => {
              console.log('error rate');
              this.modalController.dismiss('expiredsession');

              // this.helperService.alertLogin(this.router.url);
              // this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
            })
      }
    
  }
  segmentCookChanged(ev: any) {
    console.log('segmentCookChanged', ev);
    if (ev.detail.value == 'yes') {
      this.cookToggle = 1;
    } else {
      this.cookToggle = 2;
    }
  }
  segmentStepsChanged(ev: any) {
    if (ev.detail.value == 'yes') {
      this.stepsToggle = 1;
    } else {
      this.stepsToggle = 2;
    }
  }
  segmentIngQuanityChanged(ev: any) {
    if (ev.detail.value == 'yes') {
      this.ingQuantityToggle = 1;
    } else {
      this.ingQuantityToggle = 2;
    }
  }
  /**
   * close modal controller
   */
  closeModal() {
    this.modalController.dismiss('close');
  }


  getCameraRollStatus(checkboxvalue) {
    this.cameraRollStatus = checkboxvalue.currentTarget.checked;
    console.log(this.cameraRollStatus);
  }
  async dismissLoading() {
    this.loading.dismiss('img_gallery');
  }


}
