import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchrecipeService {

  constructor(public handler: HttpBackend, private http: HttpClient) { }
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  /**
   * get method for Recent and Trending search of user
   */
  fetchRecentSearch(criteria): Observable<any> {
    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'), params: { 'type': criteria }
    };
    return this.http
      .get(environment.apiEndpoint + '/recent_search', option)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  /**
   * 
   * @param data Method to clear recent search
   * 
   */

  clearRecentSearch(data): Observable<any> {
    data = JSON.stringify(data);
    // console.log(data);

    return this.http
      .post(environment.apiEndpoint + '/clear_recent_search', data, this.options)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );
  }

  /**
   * Method to fetch recipes on the basis search criteria like recipes,ingredients,cuisine 
   */

  fetchSearchCriteria(data): Observable<any> {
    // console.log(data);
    let searchFilter = JSON.parse(localStorage.getItem('searchRecipeFiltersData'));


    data.time = null;

    if (searchFilter) {
      data.imageFlag = (searchFilter.image) ? true : (!searchFilter.image) ? false : data.imageFlag;
      data.ingredients = searchFilter.ingredients;
      data.servings = searchFilter.servings;
      data.time = searchFilter.time;

    }

    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'type': data.type, 'query': data.query, 'page': data.page, 'image': data.imageFlag, 'time': data.time }
    };
    return this.http
      .get(environment.apiEndpoint + '/search', option)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  /**
   * Get search filter data from storage
   */
  getSearchRecipeFilterSettings() {
    let result = JSON.parse(localStorage.getItem('searchRecipeFiltersData'));
    return result;
  }
}
