import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

  private subject = new BehaviorSubject<any>({});
  private isImageAddedToPantry = new BehaviorSubject({isImageAdded:false});
  private imgData = [];
  sendData(data: any) {
    this.subject.next(data);
    
  }

  sendDataImageAddedToPantry(data:any){
    this.isImageAddedToPantry.next(data);
  }

  clearDataImageAddedToPantry(data:any){
    this.isImageAddedToPantry.next({isImageAdded:false});
  }
  clearData() {
    this.subject.next({});
  }

  getDataImageAddedToPantry(): Observable<any> {
    return this.isImageAddedToPantry.asObservable();
    
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  setImgData(data:any) {
    console.log('set called');
    console.log("data=", data);
    this.imgData = data;
  }

  getImgData() {
    return this.imgData;
  }

  getImg(){
    if(this.imgData.length > 0) {
      return this.imgData[0];
    }else{
      return null;
    }
  }

  clearImgData() {
    this.imgData = [];
  }

  shiftImgData() {
    console.log('shift called');
    console.log("data=", this.imgData);
    if(this.imgData.length > 0) {
      this.imgData.splice(0, 1);
    }
  }



}