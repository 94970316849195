import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { AioAuthV01Module, AuthConfig } from 'td-aio-auth';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { environment } from '../environments/environment'
import "hammerjs"; // HAMMER TIME
import { FormsModule } from '@angular/forms';
import { Network } from '@ionic-native/network/ngx';
import { CheckNetworkConnectionService } from './services/check-network-connection.service';
import { IonicStorageModule } from '@ionic/storage';
import { NetworkDetcterInterceptor } from './_helpers/network-detecter-interceptor';
import { IonicGestureConfig } from './_helpers/hammer-gesture-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';

import { AddDislikeIngredientsPage } from './add-dislike-ingredients/add-dislike-ingredients.page';
import { IngredientsIdentifiedPage } from './ingredients-identified/ingredients-identified.page';
import { ReceiptIngredientIdentifiedPage } from './receipt-ingredient-identified/receipt-ingredient-identified.page';
import { VoiceIngredientsIdentifiedPage } from './voice-ingredients-identified/voice-ingredients-identified.page';
import { RecipeSharePage } from './recipe-share/recipe-share.page';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { CustomPipe } from './pipes/custom.pipe';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { from } from 'rxjs';
import { WindowRef } from './_helpers/windowref';
import { AdMobFree } from '@ionic-native/admob-free/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
// import { TabsPageModule } from './tabs/tabs.module';


const authConfigs: AuthConfig = {
  gmailProvider: environment.gmailProvider,
  gmailAPIKey: environment.gmailAPIKey,
  FBProvider: environment.FBProvider,
  AfterLoginURL: environment.AfterLoginURL,
  loginURL: environment.loginURL,
  registerURL: environment.registerURL,
  forgetPwdURL: environment.forgetPwdURL,
  ApiURL: environment.apiEndpoint,
  IGClientid: environment.IGClientid,
  IGRedirectURL: environment.IGRedirectURL,
  regSuccessMsg: environment.regSuccessMsg,
  resetPwdURL: environment.resetPwdURL
};


@NgModule({
  declarations: [AppComponent, ForgotPasswordPage, AddDislikeIngredientsPage, CustomPipe, IngredientsIdentifiedPage, ReceiptIngredientIdentifiedPage, RecipeSharePage, VoiceIngredientsIdentifiedPage],
  entryComponents: [ForgotPasswordPage, AddDislikeIngredientsPage, IngredientsIdentifiedPage, ReceiptIngredientIdentifiedPage, VoiceIngredientsIdentifiedPage, RecipeSharePage],
  imports: [
    BrowserModule,
    // TabsPageModule,
    IonicModule.forRoot(),
    FormsModule, AppRoutingModule,
    AioAuthV01Module,
    IonicStorageModule.forRoot(),
    AioAuthV01Module.forRoot(authConfigs)
  ],
  providers: [
    StatusBar,
    CheckNetworkConnectionService,
    UniqueDeviceID,
    Network,
    SplashScreen,
    SocialSharing,
    EmailComposer,
    NetworkInterface,
    AppVersion,
    SpeechRecognition,
    Device,
    File,

    AdMobFree,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkDetcterInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    WindowRef,
    Deeplinks,
    Base64ToGallery,
    CameraPreview,
    Keyboard,
    OpenNativeSettings,
    Diagnostic,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
