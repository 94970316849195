import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { filterService } from '../services/fitler.service';
import { error } from '@angular/compiler/src/util';
import { throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../_helpers/HelperService';
import { IngredientService } from '../services/ingredient.service';

@Component({
  selector: 'app-add-dislike-ingredients',
  templateUrl: './add-dislike-ingredients.page.html',
  styleUrls: ['./add-dislike-ingredients.page.scss'],
})
export class AddDislikeIngredientsPage implements OnInit {
  public items: any;
  public filterId: String;
  public searchKey: String = '';
  public currentItem: any;
  public checkedClass: String = '';
  public searchResult: any;
  public master_list: any = [];
  public selectedIngredients: any = [];
  public popular: any = [];
  public recent: any = [];
  public addedItems: any = {};
  public itemList: any = [];
  public isItemAvailable = false;
  constructor(private modalController: ModalController, private api: filterService, private IngredientService: IngredientService, private router: ActivatedRoute, public helperService: HelperService, public navController: NavController, navParams: NavParams) {
    this.filterId = navParams.get('filterId');
  }

  ngOnInit() {

    // this.initializeItems(this.filterId);
  }
  async ionViewWillEnter() {

    let AddedItemArray = [];
    let pantryList = await this.getPantryList();

    //console.log(pantryList)
    pantryList.data.forEach(function (item) {
      item.contents.forEach(function (ele) {
        AddedItemArray.push(ele.content_id);
      })
    })
    this.addedItems = AddedItemArray;
    this.initializeItems(this.filterId);
    //this.items = '';


  }

  /**
   * Match pantry list with search data list
   */
  getPantryList() {
    return this.IngredientService.getPantryList('all').toPromise();
  }

  close() {
    this.modalController.dismiss();
  }
  /** @method: To get the data of dislike Ingredients 
   *  @param filterId 
   *  @output: Json Objects
   */
  initializeItems(filterId) {
    let msg: any = 'Please wait...';
    this.helperService.presentLoading(msg);
    this.IngredientService.list_ingredients().subscribe((resp: any) => {
      this.items = resp.data;

      this.master_list = resp.data.master_list;
      this.master_list.map(item => {
        item.isChecked = false;
      });

      this.popular = resp.data.popular;
      this.popular.map(item => {
        item.isChecked = false;
      });

      this.recent = resp.data.recent;
      this.recent.map(item => {
        item.isChecked = false;
      });
      this.helperService.dismissPresentLoading();
      let ArrayList = this.addedItems;
      console.log(this.items)
    }, error => {
      throwError("Something Wrong");
      this.helperService.dismissPresentLoading();
    })

  }

  // searchItems(ev) {

  //   let val = ev.target.value;
  //   let filter = {
  //     content_value: val
  //   };

  //   //this.initializeItems(this.filterId);
  //   if (val && val.trim() != '') {
  //     let filterData: any = {};
  //     let searchResult = [];
  //     let i = 0;
  //     this.items.forEach((element, i) => {

  //       searchResult[i] = {};
  //       searchResult[i]._id = element._id;
  //       searchResult[i].name = element.name;
  //       searchResult[i].color = element.color;
  //       searchResult[i].contents = [];

  //       element.contents.forEach((contents, index) => {

  //         if (contents.content_name.trim().toLowerCase().indexOf(val.toLowerCase()) >= 0) {
  //           searchResult[i].contents.push(element.contents[index]);
  //           element.contents[index].selected_value = true;
  //           return true;
  //         } else {
  //           element.contents[index].selected_value = false;
  //           return false;
  //         }
  //       });

  //     });
  //     this.searchResult = searchResult

  //   } else {
  //     //console.log(this.filterId)
  //     this.initializeItems(this.filterId);
  //     this.searchResult = '';
  //   }
  // }
  searchItems(ev: any) {
    // set val to the value of the searchbar
    const val = ev.target.value.toLowerCase();

    // if the value is an empty string don't filter the items
    if (val && val.trim() != '' && val.length >= 3) {
      this.isItemAvailable = true;
      this.itemList = this.master_list.filter((item) => {
        return (item.content_name.toLowerCase().trim().indexOf(val.trim()) >= 0);
      });
      this.itemList.map(item => {
        item.isChecked = false;
      });

      // console.log(val);
      // console.log(this.itemList);
    } else {
      this.isItemAvailable = false;
    }
  }
  /**
   * @Method  to set the current Dislike Ingredients
   * @param: FilterId, ContentId
   * @output boolean
   */
  addIngredient() {
    // console.log(this.currentItem);
    // if (this.currentItem) {
    //   if (Object.entries(this.currentItem).length !== 0) {


    //     this.api.add_dislike_ingredients(this.currentItem).subscribe(resp => {
    //       this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');
    //       this.modalController.dismiss();
    //       //this.navController.navigateRoot('/tabs'); 
    //     },
    //       error => {
    //         this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
    //       })
    //   } else {
    //     this.helperService.toast("Please select any item", 'danger', 'bottom', 'whatz2eat-toast');
    //   }
    // }
    console.log(this.selectedIngredients);
    if (this.selectedIngredients.length > 0) {
      let obj: any = {};
      obj.contentIds = this.selectedIngredients;

      this.api.add_dislike_ingredients(obj).subscribe(resp => {
        this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');
        this.modalController.dismiss();
      },
        error => {
          this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        })
    }


  }

  /**
  * @Method  to set the current Dislike Ingredients
  * @param: FilterId, ContentId
  * @output boolean
  */
  setSearchValue(item: any, mainIndex, index, categoryId) {

    // this.searchKey = item.content_value;
    //this.checkedClass = "checked";
    let curItem: any;
    if (typeof (this.searchResult) != "undefined") {
      curItem = this.searchResult[mainIndex]['contents'][index];
    } else {
      curItem = this.items[mainIndex]['contents'][index];
    }

    if (curItem.content_selected) {
      curItem.content_selected = false;
      let length = this.currentItem.length;
      let keyIndex = length - 1;


      for (let j = 0; j < length; j++) {
        //console.log(this.currentItem[j]['content_ids'])
        // if(this.currentItem[j].category_id == categoryId){
        if (this.currentItem[j]['content_ids'].indexOf(item.content_id) >= 0)
          //this.currentItem[j]['content_ids'].push(item.content_id);
          this.currentItem[j]['content_ids'].slice(this.currentItem[j]['content_ids'].indexOf(item.content_id), 1);

        // }            
      }

    } else {
      curItem.content_selected = true;

    }


    if (typeof (this.currentItem) != "undefined") {
      if (curItem.content_selected == false) {
        return;
      }
      let length = this.currentItem.length;
      let keyIndex = length - 1;


      for (let j = 0; j < length; j++) {

        if (this.currentItem[j].category_id == categoryId) {
          if (this.currentItem[j]['content_ids'].indexOf(item.content_id) < 0)
            this.currentItem[j]['content_ids'].push(item.content_id);
          return;
        }
      }
      this.currentItem.push({
        "category_id": categoryId,
        "content_ids": [item.content_id]
      })


    } else {
      this.currentItem =
        [{
          "category_id": categoryId,
          "content_ids": [item.content_id]
        }];

    }


  }

  selectIngredient(ingredient, type?, isChk?) {



    console.log(isChk);
    switch (type) {
      case 'recent':
        this.recent.map(item => {
          if (item.content_id == ingredient.content_id) {
            item.isChecked = !item.isChecked;
          }
        })
        break;
      case 'popular':
        this.popular.map(item => {
          if (item.content_id == ingredient.content_id) {
            item.isChecked = !item.isChecked;
          }
        });
      case 'master':
        this.itemList.map(item => {
          if (item.content_id == ingredient.content_id) {
            item.isChecked = !item.isChecked;
          }
        });
    }

    if (isChk) {
      const index = this.selectedIngredients.findIndex(x => x.content_id === ingredient.content_id);
      if (index !== undefined) this.selectedIngredients.splice(index, 1);
    } else {

      let newIngred = {};
      // newIngred["content_id"] = ingredient.content_id;
      if (this.selectedIngredients.indexOf(ingredient.content_id) < 0) {
        this.selectedIngredients.push(ingredient.content_id);
      }

    }

    console.log(this.selectedIngredients);
  }

  /**
   * filter method 
   */
  filterValue(items) {
    items.contents.forEach(element => {
      //console.log(element.content_value.toLowerCase().indexOf('man'))
      return (element.content_value.toLowerCase().indexOf('man') !== -1);
    });
    return true;
  }
}
