// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://dev.api.whatz2eat.com',
  apiEndpointShare: 'https://dev.app.whatz2eat.com',

  webClientId: '946909717571-4ihaudpraavhh0s64h2k14rdnm5snvsl.apps.googleusercontent.com',
  gmailProvider: '',
  gmailAPIKey: '',
  FBProvider:'384123552304701',
  AfterLoginURL : 'dashboard',
  loginURL: '/',
  registerURL: '/',
  forgetPwdURL: 'forget-password',
  IGClientid: 'ec7edf850ff340aa91748abebe4337b9',
  //IGClientid: 'c15329dd95894bc085c1c4d95d08a11e',
  IGRedirectURL: 'https://whatz2eat.com',
  appleClientid: 'com.whatz2eat.mobileapp',
  appleRedirectURL: 'https://dev.app.whatz2eat.com/auth/apple/redirect/',
  regSuccessMsg: "Thanks for your Interest. We'll contact you very soon",
  resetPwdURL: 'reset-password'
};
console.log('uat env');
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
