import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams, PickerController } from '@ionic/angular';
import { IngredientService } from './../services/ingredient.service';
import { HelperService } from "../_helpers/HelperService";
import { Router } from '@angular/router';
import { SharedTagIngredientService } from '../services/shared-tag-ingredient.service';
import { PickerOptions } from '@ionic/core';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-receipt-ingredient-identified',
  templateUrl: './receipt-ingredient-identified.page.html',
  styleUrls: ['./receipt-ingredient-identified.page.scss'],
})
export class ReceiptIngredientIdentifiedPage implements OnInit {

  public receiptIngredients: any;
  public QtyCounter: any = [];
  public pickerQtyCounter: any = [];
  public ingQty: any;
  public ingUnit: any;
  public currentReceiptIndex = 0;
  public currentReceiptLength;
  selected = ['', '', ''];
  public activeIngredients = [];
  public inactiveIngredients = [];
  constructor(public sharedService: SharedService, public pickerCtrl: PickerController, private sharedTagIngredientService: SharedTagIngredientService, public router: Router, public helperService: HelperService, public ingredientService: IngredientService, navParams: NavParams, public navController: NavController, public modalController: ModalController) {
    this.receiptIngredients = navParams.get('receiptIngredients');

    console.log(this.receiptIngredients);
  }

  ngOnInit() {
    // this.currentReceiptLength = this.receiptIngredients.length || 1;
    // if(this.currentReceiptLength > 1){
    // console.log(this.receiptIngredients);

    for (let j = 0; j < this.receiptIngredients.length; j++) {
      for (let i = 0; i < this.receiptIngredients[j].metadata.length; i++) {
        if (this.receiptIngredients[j].metadata[i].active == true) {
          this.activeIngredients.push(this.receiptIngredients[j].metadata[i])
        } else {
          this.inactiveIngredients.push(this.receiptIngredients[j].metadata[i])
        }
      }
    }
    console.log(this.activeIngredients);
    // }else{
    //   for(let i = 0;i<this.receiptIngredients.metadata.length;i++ ){
    //     if(this.receiptIngredients.metadata[i].active == true){
    //       this.activeIngredients.push(this.receiptIngredients.metadata[i])
    //     }else{
    //       this.inactiveIngredients.push(this.receiptIngredients.metadata[i])
    //     }
    //   }
    // }

  }
  backToExplore() {
    this.sharedService.shiftImgData();
    this.modalController.dismiss('backToExplore');
  }

  back() {
    this.modalController.dismiss();
  }

  addItemToPantry() {

    let pantryObj: any = {};
    pantryObj.pantry_img_id = this.receiptIngredients[this.currentReceiptIndex].pantry_img_id;
    pantryObj.image_type = 'receipt';


    let deleteObj: any = {};
    deleteObj.image_ids = [this.receiptIngredients[this.currentReceiptIndex].pantry_img_id];
    deleteObj.image_type = 'receipt';

    this.ingredientService.autoAddIngredient(pantryObj).subscribe(resp => {
      this.sharedService.shiftImgData();
      this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');
      this.deleteReceiptImages(deleteObj);
      let img = this.sharedService.getImg();
      if (img) {
        this.modalController.dismiss('receipt');
      } else {
        this.modalController.dismiss('ingredient');
      }




    },
      error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        this.modalController.dismiss();
      })

  }

  public deleteReceiptImages(receiptImgObj) {
    this.ingredientService.deletePantryImage(receiptImgObj).subscribe(
      (res: any) => {

      },
      error => {
        // this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }

  deleteIdentifiedIng(ingredient, index, type) {
    
    if (type == 'activeIng') {
      this.activeIngredients.splice(index, 1);
    } else {
      this.inactiveIngredients.splice(index, 1);
    }


    let deleteIdentifiedObj: any = {};
    deleteIdentifiedObj.pantry_img_id = this.receiptIngredients[this.currentReceiptIndex].pantry_img_id;
    deleteIdentifiedObj.label_obj_id = ingredient.label_obj_id;
    deleteIdentifiedObj.type = 'receipt';
    // console.log(deleteIdentifiedObj);
    this.ingredientService.deleteIdentifiedIngredient(deleteIdentifiedObj).subscribe(resp => {
      // console.log(resp);
      this.updateWhatz2eatGallery();
      this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');

    },
      error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      })
  }
  updateWhatz2eatGallery() {
    let galleryImages: any = [];

    this.ingredientService.getPantryImages('receipt_images').subscribe(
      (res: any) => {
        galleryImages = res.data;
        console.log(galleryImages);
        if (galleryImages.length > 0) {
          for (let i = 0; i < galleryImages.length; i++) {
            if (galleryImages[i].pantry_img_id == this.receiptIngredients.pantry_img_id) {
              console.log(i);
              let dataToBePassed: any[] = [];
              if (!Array.isArray(galleryImages[i])) {
                dataToBePassed.push(galleryImages[i]);
              } else {
                dataToBePassed = galleryImages[i];
              }
              this.sharedTagIngredientService.changeTagIngredients(dataToBePassed);
              this.sharedService.setImgData(dataToBePassed);
            }
          }
        }

      },
      error => {
        this.helperService.dismissPresentLoading();
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }

  /**
   * @method : To format the data as per Quantity counter
   */
  formatCounter(quantity) {
    // console.log(quantity);
    let optObj: any = {}
    return optObj = {
      "text": quantity,
      "value": quantity
    }
  }

  /**
   * Method to Update quantity of identified ingredient
   */
  updateQty(idx) {
    if (localStorage.getItem('currentUser')) {
      this.ingredientService.getQtyCounter()
        .subscribe(res => {
          // console.log(res);
          let qtyUnits: any = [];
          for (let i = 0; i < res.data.units.length; i++) {
            qtyUnits.push(res.data.units[i].unit_key)
          }
          this.QtyCounter = [
            {
              name: 'abs_qty',
              selectedIndex: 0,
              options: res.data.abs_qty.map(this.formatCounter)
            },
            {
              name: 'fraction_qty',
              selectedIndex: 0,
              options: res.data.fraction_qty.map(this.formatCounter)
            },
            {
              name: 'units',
              selectedIndex: 0,

              options: qtyUnits.map(this.formatCounter)
            }
          ];
          this.pickerQtyCounter = this.QtyCounter;
          console.log(this.pickerQtyCounter);
          if (this.pickerQtyCounter.length > 0) {
            console.log('in onup');
            this.openPicker(idx);
          }

        },
          error => {
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
            this.modalController.dismiss();
          })
    } else {
      this.modalController.dismiss();
      this.navController.navigateRoot(['/login'], { queryParams: { returnUrl: this.router.url } });
      return;
    }


  }
  async openPicker(i) {

    let opts: PickerOptions = {
      cssClass: 'quantity-picker',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'custom-line-btn lightColor',
          role: 'cancel',
          handler: () => {
            this.pickerQtyCounter = [];
          }
        },
        {
          text: 'Save',
          cssClass: 'custom-line-btn darkColor',
          handler: () => {
            /**
              * Capture the data on wheel selection on Done or cancel
              */
            picker.onDidDismiss().then(async data => {
              let abs_qty = await picker.getColumn('abs_qty');
              let fraction_qty = await picker.getColumn('fraction_qty');
              let units = await picker.getColumn('units');
              this.selected = [
                abs_qty.options[abs_qty.selectedIndex].value,
                fraction_qty.options[fraction_qty.selectedIndex].value,
                units.options[units.selectedIndex].value
              ];

              let ingredientObj: any = {};
              ingredientObj.content_id    = this.receiptIngredients.metadata[i].ingredient_id;
              ingredientObj.content_qty   = this.selected[0] + this.selected[1];
              ingredientObj.unit          = this.selected[2];
              ingredientObj.type_img_id   = this.receiptIngredients.pantry_img_id;
              ingredientObj.type          = 'receipt';

              // ingredientObj.category_id = this.receiptIngredients.metadata[i].category_id;
              // ingredientObj.content_name  = this.receiptIngredients.metadata[i].label;
              console.log(ingredientObj);
              this.ingQty = this.selected[0] + this.selected[1];
              this.ingUnit = this.selected[2];
              this.activeIngredients[i].qty = `${this.selected[0]}${this.selected[1]} ${this.selected[2]}`;


              this.ingredientService.imgEditIngredient(ingredientObj).subscribe((res: any) => {
                // if (res.status === 200) {
                this.pickerQtyCounter = [];
                this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
                // }
              }, error => {
                this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
              });
              console.log(this.selected);
            });
          }
        }
      ],
      columns: this.pickerQtyCounter
    };

    let picker = await this.pickerCtrl.create(opts);
    picker.present();



  }
}
