import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, debounceTime, delay } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ɵa } from 'td-aio-auth';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private http: HttpClient, public authenticationService: ɵa) { }
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  /**
     * method to explore recipes
     */

  fetchRecipes(data: any): Observable<any> {
    let recipeFilter = JSON.parse(localStorage.getItem('recipeFilterData'));
    // console.log(recipeFilter);
    // data.ingredients = 0;
    // data.servings = 1;
    // data.time = 0;
    data.ingredients = null;
    data.servings = null;
    data.time = null;

    if (recipeFilter) {
      data.imageFlag = (recipeFilter.image) ? true : (!recipeFilter.image) ? false : data.imageFlag;
      data.ingredients = recipeFilter.ingredients;
      data.servings = recipeFilter.servings;
      data.time = recipeFilter.time;

    }

    // console.log(data);
    let exploreRecipeHeaderOption: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'image': data.imageFlag, 'page': data.page, 'ingredients': data.ingredients, 'servings': data.servings, 'time': data.time }
    };

    return this.http
      .get(environment.apiEndpoint + '/' + data.recipeCategory, exploreRecipeHeaderOption)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }

  /**
   * Method for get ingredient using voice
   */

  audio_ingredient(postObj: Object): Observable<any> {
    return this.http.post(environment.apiEndpoint + "/audio_ingredient", postObj, this.options)
      .pipe(map(res => {
        return res;
      },
        err => {
          throwError(err);
        })
      );
  }


  /**
   * method to dislike recipe
   */

  disLikeRecipe(recipe_id): Observable<any> {
    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'recipe_id': recipe_id }
    };
    return this.http
      .get(environment.apiEndpoint + '/dislike_recipe', option)
      .pipe(map(res => {
        return res;
      }, err => {
        throwError(err);
      })
      );

  }


  /**
   * method to mark as favorite recipe
   */
  markFavouriteRecipe(postObj: Object): Observable<any> {
    return this.http.post(environment.apiEndpoint + "/favourite_recipe", postObj, this.options)
      .pipe(map(res => {
        return res;
      },
        err => {
          throwError(err);
        })
      );
  }
  /** 
   * method to strike out the recipe step
   */
  strikeOutRecipeStep(postObj: Object): Observable<any> {
    return this.http.post(environment.apiEndpoint + "/step_completed", postObj, this.options)
      .pipe(delay(500), map(res => {
        return res;
      },
        error => {
          throwError(error);
        }));
  }

  /**
   * method to give rating to recipe
   */
  giveRecipeRating(postOb: Object): Observable<any> {
    return this.http.post(environment.apiEndpoint + '/user_rating', postOb, this.options)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }

  /**
   * get feedback opf recipe
   */
  fetchFeedbackOfRecipe(recipe_id) {
    let option: any = {
      params: { 'recipe_id': recipe_id }
    };
    return this.http.get(environment.apiEndpoint + '/getfeedback', option)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }
  /**
   * method to save feedback of recipe
   */
  saveShareRating(postOb: Object): Observable<any> {
    return this.http.post(environment.apiEndpoint + '/feedback', postOb, this.options)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }

  /**
   * method to get recipe detail throuh recipe id
   */

  getRecipeDetailById(id): Observable<any> {
    let option: any = {
      params: { 'recipe_id': id }
    };

    return this.http.get(environment.apiEndpoint + "/recipe_detail", option)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))
  }

  /**
   * method to finished cooking
   */
  finishedCooking(postObj): Observable<any> {

    return this.http.post(environment.apiEndpoint + "/finish_recipe", postObj, this.options)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error)
        }))
  }

  /**
   * Method to subtact ingredients from recipe
   */
  subtractRecipeIng(postObj): Observable<any> {

    return this.http.post(environment.apiEndpoint + "/subtract_recipe_ingredients", postObj, this.options)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error)
        }))
  }

  /**
   * method to get Nutritions of recipe
   */
  getNutritions(recipe_id): Observable<any> {
    let option: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: { 'recipe_id': recipe_id }
    };
    return this.http.get(environment.apiEndpoint + "/show_nutrients", option)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error)
        }))
  }

  /**
   * save filter data
   */
  saveFilterData(filterData) {
    localStorage.setItem('recipeFilterData', JSON.stringify(filterData));
    return true;
    // remove return to comunicate with API
    return this.http.post(environment.apiEndpoint + "save_filter_data", filterData)
      .pipe(map(res => {
        return res;
      },
        error => {
          throwError(error);
        }))

  }

  /**
   * Get filter data from storage
   */
  getRecipeFilterSettings() {
    let result = JSON.parse(localStorage.getItem('recipeFilterData'));
    return result;

    if (this.authenticationService.currentUserValue != null) {
      if (this.authenticationService.currentUserValue.token != "") {
        //Need to all the API here
      }
    } else {
      let result = JSON.parse(localStorage.getItem('recipeFilterData'));
      if (result) {
        return result;
      } else {
        return null;
      }

    }
  }

}
