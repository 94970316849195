import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { IngredientService } from '../services/ingredient.service';
import { HelperService } from '../_helpers/HelperService';

@Component({
  selector: 'app-voice-ingredients-identified',
  templateUrl: './voice-ingredients-identified.page.html',
  styleUrls: ['./voice-ingredients-identified.page.scss'],
})
export class VoiceIngredientsIdentifiedPage implements OnInit {
  public identifiedIngredients: any;


  constructor(navParams: NavParams,
    public modalController: ModalController,
    public ingredientService: IngredientService,
    public helperService: HelperService) {

    this.identifiedIngredients = navParams.get('fetchedVoiceIngredients');
  }

  ngOnInit() {
  }

  backToExplore() {
    this.modalController.dismiss('gotoExplore');
  }

  back() {
    console.log('back call');
    this.modalController.dismiss('goBackToSay');
  }

  addItemToPantry() {
    let contentIds = [];
    for (let i = 0; i < this.identifiedIngredients.length; i++) {
      contentIds.push(this.identifiedIngredients[i].content_id)
    }
    let obj: any = {};
    obj.contentIds = contentIds;
    console.log(obj);
    this.ingredientService.voiceAddIngredient(obj).subscribe(resp => {
      this.helperService.toast(resp.message, 'primary', 'bottom', 'whatz2eat-toast');
      this.modalController.dismiss('addVoiceIngredient');
    },
      error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      })

  }
}
