import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { map, catchError, retryWhen } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class filterService {

    token = localStorage.getItem('token'); 

    constructor(private http: HttpClient) { }

    private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    getFilterList() {
        return this.http.get(environment.apiEndpoint + '/list_filters', this.options)
        //return this.http.get('../assets/data.json', this.options)
            .pipe(map(res => {
                return res;
            }, error => {
                throwError(error);
            })
            );
    }
    /**
     * Description: To list down the filter's data as per filter Id
     * @param titleId 
     * @output observable
     */
    getfilterDataBasedOnTitle(titleId){ 
        let filter_options = { 
            headers: new HttpHeaders().set('Content-Type', 'application/json'), 
            params: { 
                "filter_id": titleId 
            } 
        };
        
        return this.http.get(environment.apiEndpoint + '/list_filters', filter_options)  
            .pipe(map(
                res => {
                    return res;
                },
                error =>{
                    throwError(error);
                }
            ));
    }

    saveFilterItems(body: Object): Observable<any> {
        body = JSON.stringify(body);
        return this.http
            .post(environment.apiEndpoint + '/list_filters/edit', body, this.options)
            .pipe(map(res => {
                return res;
            }, error => {
                throwError(error);
            })
            );

    }

    /**
     * add_dislike_ingredients
     * @param body 
     */

    add_dislike_ingredients(body: Object): Observable<any> {
        body = JSON.stringify(body);
        return this.http
            .post(environment.apiEndpoint + '/add_dislike_ingredients', body, this.options)
            .pipe(map(res => {
                return res;
            }, error => {
                throwError(error);
            })
            );
    }

    deleteEntireItems(body: Object){
        body = JSON.stringify(body);
        return this.http
            .post(environment.apiEndpoint + '/list_filters/clear_all', body, this.options)
            .pipe(map(res => {
                return res;
            }, error => {
                throwError(error);
            })
            );
    }

    deleteFilterItems(deleteData:Object) {
        deleteData = JSON.stringify(deleteData);
        return this.http
        .post(environment.apiEndpoint + '/list_filters/edit', deleteData, this.options)
        .pipe(map(res => {
            return res;
        }, error => {
            throwError(error);
        })
        );
    }

    /**
     * delete_ingredient
     */

    delete_ingredient(deleteData:Object) {
        deleteData = JSON.stringify(deleteData);
        return this.http
        .post(environment.apiEndpoint + '/delete_ingredient', deleteData, this.options)
        .pipe(map(res => {
            return res;
        }, error => {
            throwError(error);
        })
        );
    }


    /**
     * show_dislike_ingredients
     */
    public show_dislike_ingredients(){
        let filter_options = { 
            headers: new HttpHeaders().set('Content-Type', 'application/json')           
        };
        
        return this.http.get(environment.apiEndpoint+'/show_dislike_ingredients',filter_options)  
        .pipe(map(
            res => {
                
                return res;
            },
            error =>{
                throwError(error);
            }
        ));
        
    }

    /**
     * show dislike recipe
     */
    public show_dislike_recipe(filter_id){
        let option: any = {
            params: { 'filter_id': filter_id }
        };
        
        return this.http.get(environment.apiEndpoint+'/list_filters',option)  
        .pipe(map(
            res => {
                
                return res;
            },
            error =>{
                throwError(error);
            }
        ));
        
    }
    /**
     * delete_dislike_ingredient
     */ 
    delete_dislike_ingredient(content_id):Observable<any>{
        
        let option: any = {
            params: { 'content_id': content_id }
        };

        return this.http
        .delete(environment.apiEndpoint + '/delete_dislike_ingredient', option)
        .pipe(map(res => {
            return res;
        }, err => {
            throwError(err);
        })
        );
  }
  /**
     * delete_dislike_ingredient
     */ 
    deleteDislikeRecipe(recipe_id):Observable<any>{
        let option: any = {
            params: { 'recipe_id': recipe_id }
        };
        return this.http
        .delete(environment.apiEndpoint + '/delete_dislike_recipe', option)
        .pipe(map(res => {
            return res;
        }, err => {
            throwError(err);
        })
        );
  }
  

  /**
   * saveMeasureUnit
   */
  saveMeasureUnit(dataObj):Observable<any>{
      
      return this.http.post(environment.apiEndpoint+'/metric_toggle',dataObj)
      .pipe(map( res => {
          return res;
      },
      error => {
          return  error;
      }))
  }
}