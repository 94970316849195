import { Component, OnInit } from '@angular/core';
import { RecipeService } from '../services/recipe.service';
import { HelperService } from '../_helpers/HelperService';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController, PickerController, ModalController } from '@ionic/angular';
import { ɵa } from 'td-aio-auth';
import { PickerOptions } from '@ionic/core';
import { filterService } from '../services/fitler.service';
import { SharedTabService } from "../services/shared-tab.service";
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { RecipeSharePage } from './../recipe-share/recipe-share.page';
import { Location } from '@angular/common';
import { WindowRef } from '../_helpers/windowref';
import { SearchrecipeService } from '../services/searchrecipe.service';
import { AdMobFree, AdMobFreeBannerConfig } from '@ionic-native/admob-free/ngx';
import { SocialSharingService } from '../services/social-sharing.service';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.page.html',
  styleUrls: ['./recipe-detail.page.scss'],
})
export class RecipeDetailPage implements OnInit {
  public recipeFilter: any = {};


  public activeRecipeTab = 'overview';
  public recipe_detail: any = {};
  public recipeId: String = '';
  public strikeOut: Boolean = false;
  public ratings: any = [];
  public userRatings: any = [];
  public currentIndex = 0;
  public servings: any = {};
  public currentServing = 1;
  public pickerQtyCounter: any;
  public pickerOption: any = [];
  public respRecived: boolean = true;
  public totalRate: any = {};
  public tappedRating: any;
  public recipeTag: any;

  public recipeTabs = ['overview', 'ingredients', 'steps'];
  constructor(
    public admobFree: AdMobFree,
    public searchService: SearchrecipeService,
    private modalController: ModalController,
    private tts: TextToSpeech,
    private sharedTabService: SharedTabService,
    public api: RecipeService,
    public helperService: HelperService,
    private route: Router,
    private router: ActivatedRoute,
    public alertController: AlertController,
    public navCtrl: NavController,
    public authenticationService: ɵa,
    public pickerCtrl: PickerController,
    private _filterService: filterService,
    private location: Location,
    private windowref: WindowRef,
    private socialSharing: SocialSharingService,
  ) {
    // this.sharedTabService.changeStateOfTabs(false);


    this.recipeId = this.router.snapshot.params.id;
    this.router.queryParams.subscribe(params => {
      if (this.route.getCurrentNavigation().extras.state) {
        this.recipeTag = this.route.getCurrentNavigation().extras.state.recipeTag;
        // console.log('recipeTag = ' + this.recipeTag);
        if (this.recipeTag == 'searchRecipe') {
          this.recipeFilter = this.searchService.getSearchRecipeFilterSettings();
        } else {
          this.recipeFilter = this.api.getRecipeFilterSettings();
        }


        // console.log('recipeFilter = ' + JSON.stringify(this.recipeFilter));
        this.currentServing = (this.recipeFilter) ? (this.recipeFilter.servings) : 1;
        // console.log('currentServing = ' + this.currentServing);
        this.servings = [
          {
            "num": "1",
            "value": "1 Serving"
          },
          {
            "num": "2",
            "value": "2 Servings"
          },
          {
            "num": "3",
            "value": "3 Servings"
          },
          {
            "num": "4",
            "value": "Party"
          }
        ];
        // console.log(this.servings);
      }
    });


  }


  ngOnInit() {
    // this.openTab(this.activeRecipeTab, 0)
    // this.recipeDetail();   

  }

  /*  ngViewWillEnter(){
     this.openTab(this.activeRecipeTab,0)
     this.recipeDetail();
   } 
   */
  ionViewWillEnter() {
    if (this.currentServing >= 4) {
      this.servings[3].value = this.currentServing + " Servings "
    }
    // console.log(this.servings);
    //this.sharedTabService.changeStateOfTabs(false);
    // console.log('ionViewWillEnter');
    this.showBannerAds();
  }
  ionViewDidEnter() {
    this.openTab(this.activeRecipeTab, 0)
    this.recipeDetail();
  }

  openTab(tabName, idx) {
    this.currentIndex = idx;
    this.activeRecipeTab = tabName;
    // console.log(tabName);
    // console.log(idx);
  }


  /**
   * Get recipe detail
   */
  recipeDetail() {
    this.api.getRecipeDetailById(this.recipeId)
      .subscribe(res => {

        this.recipe_detail = res.data[0];
        this.totalRate = this.recipe_detail.total_rating;
        this.tappedRating = this.recipe_detail.user_rating;
        // console.log(this.recipe_detail);
        // this.currentServing =  (this.recipe_detail.number_of_servings) ? this.recipe_detail.number_of_servings : 1;

        /*  if(this.currentServing >=4 ){
           this.servings[3].value = this.currentServing + " Servings "
         } */
        //this.recipe_detail.rating = 3;
        this.ratings = [];
        this.userRatings = [];
        for (let i = 1; i <= 5; i++) {
          if (i <= this.totalRate.rating) {
            this.ratings.push({ 'rated': true });

          } else {
            this.ratings.push({ 'rated': false });
          }
          if (i <= this.tappedRating) {
            this.userRatings.push({ 'rated': true });

          } else {
            this.userRatings.push({ 'rated': false });
          }

        }
        // console.log(this.ratings);
        this.respRecived = true;

      },
        error => {
          this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        }
      )
  }

  /**
   * method to mark recipe as in dislike throuh recipe detail page.
   */
  markDislike() {
    if (this.authenticationService.currentUserValue === null) {
      //this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;
    }

    this.respRecived = false;
    this.recipe_detail.favorite = false;
    //this.recipe_detail.dislike = !this.recipe_detail.dislike;

    if (this.recipeId != "undefined") {

      if (this.recipe_detail.dislike) {

        this._filterService.deleteDislikeRecipe(this.recipeId).subscribe((res: any) => {
          if (res.status === 200) {
            this.respRecived = true;
            this.recipe_detail.dislike = false;
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');

          }
        }, error => {
          this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
        });
      } else {
        this.recipe_detail.dislike = true;
        this.api.disLikeRecipe(this.recipeId)
          .subscribe(res => {
            this.respRecived = true;
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
          },
            error => {
              this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
            })
      }

    }
  }

  /**
   * method to mark step as done
   */
  stepCompleted(index, stepNumber) {

    if (this.authenticationService.currentUserValue === null) {
      //this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;
    }

    this.respRecived = false;
    let isStrike = this.recipe_detail.steps[index].strikeOut;
    if (isStrike == true) {
      isStrike = false;
    } else {
      isStrike = true;
    }
    this.recipe_detail.steps[index].strikeOut = isStrike;
    if (this.recipeId != "undefined") {
      let postObj = {
        "recipe_id": this.recipeId,
        "step": stepNumber,
        "active": '' + isStrike + ''
      };

      this.api.strikeOutRecipeStep(postObj)
        .subscribe(res => {
          this.respRecived = true;
          //this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
        },
          error => {
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          })
    }
  }

  /**
   * finished cooking
   */
  async confirmFinished() {
    if (this.authenticationService.currentUserValue === null) {
      //this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;
    } else {
      await this.presentAlertConfirm();
    }
    this.respRecived = false;

  }

  /**
   * subract ingredients from pantry
   */
  subtractIng() {
    if (this.authenticationService.currentUserValue === null) {
      //this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;
    } else {
      if (this.recipeId != "undefined") {
        let postData = {
          "recipe_id": this.recipeId,
          "serving": this.currentServing
        };
        this.api.subtractRecipeIng(postData)
          .subscribe(res => {
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
          },
            error => {
              this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
            })
      }
    }
  }


  /**
   * final method to send request for finished cooking
   */
  finishedCooking() {
    if (this.recipeId != "undefined") {
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      let postData = {
        "recipe_id": this.recipeId
      };
      this.api.finishedCooking(postData)
        .subscribe(res => {
          //this.respRecived =  true;
          this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');

          this.rateAndShareModal();
          // this.navCtrl.navigateForward(['recipe-share']);
          // this.recipeDetail();
        },
          error => {
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          })
    }
  }

  /**
   * Rate and share modal
   */
  async rateAndShareModal() {
    const modal = await this.modalController.create({
      component: RecipeSharePage,
      componentProps: {
        'receiptId': this.recipeId,
        'tappedRating': this.tappedRating,
        'recipe_image': this.recipe_detail.recipe_image
      }
    });
    modal.onDidDismiss().then((dataReturned) => {
      console.log(JSON.stringify(dataReturned));

      if (dataReturned.data == 'done') {
        this.navCtrl.navigateRoot(['/menu/explore']);
      } else if (dataReturned.data == 'expiredsession') {
        //do nothing
      } else {
        this.recipeDetail();
        this.admobFree.banner.show()
          .then(() => {
            //show the banner
          })
          .catch(e => console.log(e));
      }

    });

    return await modal.present();
    //     const { data } = await modal.onWillDismiss();
    // console.log(data);
  }
  /**
   * Finsihed cooking confirmation
   */
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: 'Finished Cooking!',
      message: 'Are you sure?',
      cssClass: 'alertLogin',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
            this.respRecived = true;
          }
        },
        {
          text: 'Confirm',
          cssClass: 'alertText',
          handler: () => {
            this.finishedCooking();
            // console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * Rate this recipe
   */
  giveRating(rating) {
    // console.log(rating);
    // return false;
    if (this.authenticationService.currentUserValue === null) {
      //this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;
    } else {
      this.tappedRating = (rating + 1);
      let postObj = {
        "recipe_id": this.recipeId,
        "rating": rating + 1
      }
      // console.log(postObj);
      if (this.recipeId != "undefined") {
        this.api.giveRecipeRating(postObj)
          .subscribe(res => {
            // console.log('success rate');
            this.userRatings = [];
            for (let i = 1; i <= 5; i++) {
              if (i <= this.tappedRating) {
                this.userRatings.push({ 'rated': true });

              } else {
                this.userRatings.push({ 'rated': false });
              }
            }

            // this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
          },
            error => {
              // console.log('error rate');
              this.admobFree.banner.hide()
                .then(() => {
                  //removing the banner
                })
                .catch(e => console.log(e));
              this.helperService.alertLogin(this.route.url);
              return false;
              // this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
            })
      }
    }

  }
  /**
   * method to go back to explorer page from reipe detail page
   */
  backToExplorer() {
    
    //this.navCtrl.navigateRoot(['/menu/explore']);  
    if (this.windowref.nativeWindow.history.length > 1) {
      // this.location.back();
      this.navCtrl.back();
    } else {
    this.navCtrl.navigateRoot(['/menu/explore']);
    }
  }
  /**
   * method to add recipe as a favorite
   */
  addFavorite() {
    this.recipe_detail.dislike = false;
    this.respRecived = false;

    let isFav = "";

    if (this.authenticationService.currentUserValue === null) {
      // this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: this.route.url } });
      // return;
      this.admobFree.banner.hide()
        .then(() => {
          //removing the banner
        })
        .catch(e => console.log(e));
      this.helperService.alertLogin(this.route.url);
      return;

    }

    if (this.recipeId != "undefined") {
      if (this.recipe_detail.favorite) {
        isFav = "false";
      } else {
        isFav = "true";
      }

      let postObj = { "recipe_id": this.recipeId, "active": isFav };

      this.api.markFavouriteRecipe(postObj)
        .subscribe(res => {
          this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
          this.respRecived = true;
          this.recipe_detail.favorite = !this.recipe_detail.favorite;
        },
          error => {
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          })
    }

    //this.navCtrl.navigateRoot(['/']);
  }
  recipeNutrition() {

    // [routerLink]="['/menu/explore/nutrition']"
    // let navigationExtras: NavigationExtras = {
    //   state: {
    //     recipeId: this.recipeId
    //   }
    // };

    this.navCtrl.navigateForward(['/menu/explore/recipe-detail/' + this.recipeId + '/nutrition']);

  }

  /**
   * method to select no. of servings
   */
  async chooseServings(num) {
    if (num < 4) {
      this.currentServing = num;
      this.servings[3].value = " Party "

    } else {
      this.pickerOption = [];
      for (let i = 4; i <= 21; i++) {
        this.pickerOption.push({
          "text": i,
          "value": i
        })
      }
      await this.openPicker();

    }
    //alert(this.currentServing);

    /*  this.recipe_detail.ingredients.forEach((element, index) => {     
       element.amount = element.amount * num;
       
    });  */

  }

  async openPicker() {

    let QtyCounter = [
      {
        name: 'servings',
        electedIndex: 0,
        options: this.pickerOption
      }


    ];
    this.pickerQtyCounter = QtyCounter;

    let opts: PickerOptions = {
      cssClass: 'quantity-picker',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'custom-line-btn lightColor',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Save',
          cssClass: 'custom-line-btn darkColor',
          handler: () => {
            picker.onDidDismiss().then(async data => {
              let servings = await picker.getColumn('servings');
              this.currentServing = servings.options[servings.selectedIndex].value;
              this.servings[3].value = this.currentServing + " Servings "


            });
          }
        }
      ],
      columns: this.pickerQtyCounter
    };

    let picker = await this.pickerCtrl.create(opts);
    picker.present();



  }

  /**Method for adbanner */

  showBannerAds() {
    const bannerConfig: AdMobFreeBannerConfig = {
      // add your config here
      // for the sake of this example we will just use the test config
      // isTesting: true,
      autoShow: false,
      id: 'ca-app-pub-1581929061926753/8879679953'

    };
    this.admobFree.banner.config(bannerConfig);

    this.admobFree.banner.prepare()
      .then(() => {
        // banner Ad is ready
        // if we set autoShow to false, then we will need to call the show method here
        this.admobFree.banner.show()
          .then(() => {
            //show the banner
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));
  }

  ionViewWillLeave() {
    this.admobFree.banner.remove()
      .then(() => {
        //removing the banner
      })
      .catch(e => console.log(e));
  }

  speak() {
    this.tts.speak('PARIS: Prime Minister Narendra Modi on Friday asserted that Read more at ')
      .then(() => console.log('Success'))
      .catch((reason: any) => console.log(reason));
  }

  shareRecipe() {
    console.log(this.recipeId);

    this.socialSharing.share(this.recipeId)
      .then(
        success => {
          console.log(success);
        },
        reject => {
          console.log('reject: ' + reject);
        }
      )
      .catch(error => {
        console.log('catch: ' + error);
      });
  }

}