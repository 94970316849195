import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { RegistrationService } from './../services/registration.service';
import { HelperService } from "../_helpers/HelperService";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
  // pattern match variable
  public showHints = false;
  public pwdPatternError = 0;
  public isUpper = false;
  public isLower = false;
  public isSpecial = false;
  public isEightPlus = false;


  public emailFlag: boolean = false;
  public otpFlag: boolean = false;
  public newPwdFlag: boolean = false;
  public toggleNewPassword = true;

  public generatedToken;
  public userEmail: any;
  public newPwd: any;
  public otpVal = '';
  // public otpFirst = '';
  // public otpSecond = '';
  // public otpThird = '';
  // public otpFourth = '';
  // public otpFifth = '';
  // public otpSixth = '';
  public pwdPatternFlag = false;
  constructor(public navCtrl: NavController, public helperService: HelperService, public registrationService: RegistrationService, private modalController: ModalController) { }

  ngOnInit() {
    this.emailFlag = true;
  }

  close() {

    this.modalController.dismiss();
  }
  onUserEmail(val) {
    this.userEmail = val;
  }
  onToggleNewPassword() {
    console.log('toggleNewPassword');
    this.toggleNewPassword = !this.toggleNewPassword;
    console.log(this.toggleNewPassword);
  }
  public getType() {
    return this.toggleNewPassword ? 'password' : 'text';
  }

  getText(e) {
    console.log(e);
    let elementValue: any = e.srcElement.value;
    // if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%!]).{8,20}/.test(elementValue)) {
      
      if (/(?=.*[a-z])(?=.*[A-Z])(?=.*[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]).{8,50}/.test(elementValue)) {
      console.log('pattern match');
      this.pwdPatternFlag = true;
    } else {
      console.log(' not match');
      this.pwdPatternFlag = false;
    }
  }

  sendEmail() {
    if (this.userEmail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmail)) {

        let obj: any = {};
        obj.email = this.userEmail;
        let msg: any = "Sending OTP...";
        // this.presentLoading(msg);
        this.helperService.presentLoading(msg);

        this.registrationService.generateOtp(obj).subscribe(
          (res: any) => {
            if (res) {
              this.helperService.dismissPresentLoading();
              // console.log(res);
              if (res.status == 200) {
                this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');

                this.generatedToken = res.token;
                this.emailFlag = false;
                this.otpFlag = true;
                this.newPwdFlag = false;
              }
            }
          }, error => {
            console.log('in err..');
            this.helperService.dismissPresentLoading();
            this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
          }
        )
      } else {
        let msg: any = 'YOU HAVE ENTERED AN INVALID EMAIL ADDRESS!';
        this.helperService.toast(msg, 'danger', 'bottom', 'whatz2eat-toast');
      }
    } else {
      let msg: any = 'INPUT FIELD SHOULD NOT BE BLANK!';
      this.helperService.toast(msg, 'danger', 'bottom', 'whatz2eat-toast');
    }
  }

  // onKeyUpEvent(event, next, prev) {
    
  //   this.otpVal = this.otpFirst + '' + this.otpSecond + '' + this.otpThird + '' + this.otpFourth + '' + this.otpFifth + '' + this.otpSixth;

  //   if (event.target.value.length < 1 && prev) {
  //     prev.setFocus();
  //   }
  //   else if (next && event.target.value.length > 0) {
  //     next.setFocus();
  //   }
  //   else {
  //     // console.log('in else');
  //     return 0;
  //   }
  // }

  resendOtp() {
    this.otpVal = '';
    // this.otpFirst = '';
    // this.otpSecond = '';
    // this.otpThird = '';
    // this.otpFourth = '';
    // this.otpFifth = '';
    // this.otpSixth = '';
    let obj: any = {};
    obj.email = this.userEmail;
    this.registrationService.generateOtp(obj).subscribe(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');

            this.generatedToken = res.token;
            this.emailFlag = false;
            this.otpFlag = true;
            this.newPwdFlag = false;

          }
        }
      }, error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }
  submitOtp() {

    let verifyOtpObj: any = {};
    verifyOtpObj.token = this.generatedToken;
    verifyOtpObj.otp = this.otpVal.trim();
    this.registrationService.VerifyOtp(verifyOtpObj).subscribe(
      (res: any) => {
        if (res) {
          console.log(res);
          if (res.status == 200) {
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
            this.otpVal = '';
            // this.otpFirst = '';
            // this.otpSecond = '';
            // this.otpThird = '';
            // this.otpFourth = '';
            // this.otpFifth = '';
            // this.otpSixth = '';
            this.emailFlag = false;
            this.otpFlag = false;
            this.newPwdFlag = true;
          }
        }
      }, error => {
        console.log('in err..')
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }
  setNewPwd() {
    if(this.pwdPatternFlag === false){
      this.helperService.toast("Password pattern error", 'danger', 'bottom', 'whatz2eat-toast');
    }

    let newPwdObj: any = {};
    newPwdObj.token = this.generatedToken;
    newPwdObj.password = this.newPwd;
    this.registrationService.resetPassword(newPwdObj).subscribe(
      (res: any) => {
        if (res) {
          console.log(res);
          if (res.status == 200) {
            this.helperService.toast(res.message, 'primary', 'bottom', 'whatz2eat-toast');
            // this.navCtrl.navigateRoot('/login');
            this.modalController.dismiss();
          }
        }
      }, error => {
        this.helperService.toast(error, 'danger', 'bottom', 'whatz2eat-toast');
      }
    )
  }
   /**
   * Check the password pattern on blur
   */
  checkPwdPattern(Regpwd) {
    if (Regpwd == "") {
      this.showHints = false;
    } else {
      this.showHints = true;
    }

    if (Regpwd.search(/[a-z]/) >= 0) {
      this.isLower = true;
    } else {
      this.isLower = false;
      this.pwdPatternError++;
    }

    if (Regpwd.search(/[A-Z]/) >= 0) {
      this.isUpper = true;
    } else {
      this.isUpper = false;
      this.pwdPatternError++;
    }

    if (Regpwd.search(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/) >= 0) {
      this.isSpecial = true;
    } else {
      this.isSpecial = false;
      this.pwdPatternError++;
    }

    if (Regpwd.length >= 8) {
      this.isEightPlus = true;
      this.pwdPatternError++;
    } else {
      this.isEightPlus = false;
    }

    if (this.isEightPlus === true && this.isLower === true && this.isUpper === true && this.isSpecial === true) {
      this.pwdPatternFlag = true;
      return true;
    } else {
      this.pwdPatternFlag = false;
      return false;
    }
  }


}
