import { Injectable } from "@angular/core";
import { ToastController, LoadingController, AlertController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor(public loadingController: LoadingController, private toastController: ToastController, private alertController: AlertController, private navController: NavController, private route:Router) { }

    async toast($message, $color = "", $position, $class) {
        const toast = await this.toastController.create({
            message: "<p class='messageBox'>" + $message + "</p>",
            color: ($color != "") ? $color : "",
            position: $position,
            cssClass: $class,
            duration: 2000,            
            // buttons: [
            //     {
            //         //side:'start',                    
            //         //icon: $icon,
            //         //text: 'Favorite',
            //         handler: () => {
            //             console.log('Favorite clicked');
            //         }
            //     }
            // ]
        });
        toast.present();
    }

    async presentLoading(msg) {
        const loading = await this.loadingController.create({
            message: msg,
            // duration: 2000,
            id: 'present_loading',
            cssClass: 'animation-wrapper',
        });
        await loading.present();

        // const { role, data } = await loading.onDidDismiss();
        // console.log('Loading dismissed!');
    }
    async dismissPresentLoading() {
        this.loadingController.dismiss('present_loading');
    }

    async funkyLoader(msg) {
        const loading = await this.loadingController.create({
            message: msg,
            spinner: null,
            cssClass: 'funky-animation-wrapper',
        });
        await loading.present();
        const { role, data } = await loading.onDidDismiss();
        console.log('Loading dismissed!');
    }

    async alertLogin(stateurl) {
        const alert = await this.alertController.create({
          header: 'Enjoying Whatz2eat?',
          //subHeader: 'Subtitle',
          message: 'Let your taste buds explore a little bit more.',
          cssClass:'alertLogin',
          buttons: [
            {
              text: 'Sign In / Sign Up',
              cssClass:'alertText',
              handler: () => {
                this.navController.navigateRoot(['/login'],  { queryParams: { returnUrl: stateurl }});
              }
            }, {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'cancel',
                handler: () => {
                  // const url = this.route.url;
                  // if (url === '/menu/barcode')
                  //   this.route.navigate([url]);
                  console.log('Confirm Cancel');
                }
              }
          ]
        });
    
        await alert.present();
      }

}